import React from 'react'
import tw from 'twin.macro'
import Logo from '../assets/aventine_logo_collapsed_black.svg'

const ThanksForSubscribing = () => {
  return (
    <div
      className="flex items-center justify-center"
      style={{ height: '100vh' }}
    >
      <div className="text-center">
        <div className='flex items-center justify-center '>
            <img
                src={Logo}
                alt="logo"
                className="mx-auto sm:mx-0 sm:mb-auto w-1/5 sm:w-1/2 lg:w-1/3"
            />
        </div>
        <h1 className='text-4xl font-serif' style={{marginTop:'40px', marginBottom:'40px'}}>Thanks for subscribing!</h1>
        <a href='https://www.aventine.org/'>
          <button
            className="bg-av-purple rounded-lg text-white text-2xl tk-acumin-pro-wide"
            style={{
              paddingRight: '36px',
              paddingLeft: '36px',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
          >
            CONTINUE TO OUR WEBSITE
          </button>
        </a>
      </div>
    </div>
  )
}

export default ThanksForSubscribing
